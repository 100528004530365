<template>
  <div class="container container_result">
    <div class="navBar_m">
      <div class="leftIcon iconfont icon-fanhui" @click="back()"></div>
      <div class="navBarText_m">意见反馈详情</div>
    </div>
    <div class="listR">
      <div class="listTitle">我的反馈</div>
      <div class="time">{{ listData.myfeedback.time }}</div>
      <div class="content">{{ listData.myfeedback.content }}</div>
    </div>
    <div class="listR">
      <div class="listTitle">平台回复</div>
      <div class="time">{{ listData.platform.time }}</div>
      <div class="content">{{ listData.platform.content }}</div>
    </div>
  </div>
</template>
icon-ai-message
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      listData: {
        myfeedback: {
          id: 0,
          time: '2020-8-5  20:44',
          content:
            '我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈...'
        },
        platform: {
          id: 0,
          time: '2020-8-5  20:44',
          content:
            '平台回复平台回复平台回复平台回复平台回复平台回复平台回复平台回复。'
        }
      }
    }
  },
  mounted() {
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
      // let bottom = document.getElementsByClassName('bottom')[0]
      // bottom.style.display = 'none'
    }
  },
  computed: {
    ...mapState(['mobile'])
  },
  methods: {
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/zh-CN/home' })
        return false
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="less">
.container_result {
  width: 100%;
  padding: 0 0.27rem;
  .listR {
    display: block;
    width: 100%;
    // height: 2.88rem;
    padding: 0.26rem 0.22rem;
    background-color: #ffffff;
    border-radius: 0.1rem;
    box-sizing: border-box;
    margin-bottom: 0.2rem;
    .listTitle {
      width: 100%;
      font-family: SourceHanSansCN-Normal;
      font-size: 0.3rem;
      color: #424242;
    }
    .time {
      width: 100%;
      font-family: SourceHanSansCN-Normal;
      font-size: 0.24rem;
      color: #999999;
    }
    .content {
      width: 100%;
      //   height: 42px;
      font-family: SourceHanSansCN-Regular;
      font-size: 0.24rem;
      line-height: 0.29rem;
      color: #666666;
      margin-top: 0.33rem;
      padding-bottom: 0.6rem;
    }
    .bottomLine {
      width: 100%;
      height: 1px;
      background-color: #eff0f4;
      margin: 0.33rem 0 0.14rem 0;
    }
    .itemBottom {                                                                                                                                                                 
      width: 100%;
      height: 23px;
      font-family: SourceHanSansCN-Regular;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .itemLeft {
        font-family: SourceHanSansCN-Regular;
        font-size: 0.24rem;
        color: #999999;
      }
    }
  }
}
.navBar_m {
  display: block;
  width: 100%;
  height: 46px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .leftIcon {
    width: 40px;
    height: 100%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    position: relative;
    z-index: 6;
  }
  .navBarText_m {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    font-family: SourceHanSansSC-Regular;
    font-size: 18px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
