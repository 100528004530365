<template>
  <div class="container container_video">
    <div class="navBar_m">
      <div class="leftIcon iconfont icon-fanhui" @click="back()"></div>
      <div class="navBarText_m">详情</div>
    </div>
    <div class="video_title">{{ details.title }}</div>
    <div class="videoWrap">
      <div class="left_video">
        <div class="video">
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            :infinite-scroll-immediate="false"
          ></video-player>
        </div>
      </div>
      <div class="right_list">
        <div class="right_top">相关课程</div>
        <div class="infinite-list-wrapper" style="overflow:auto">
          <ul
            class="list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="disabled"
            infinite-scroll-distance="10"
            :infinite-scroll-immediate="false"
          >
            <li
              v-for="item in videoList"
              class="list-item"
              :key="item.id"
              @click="getDetails(item.id, plate_id)"
            >
              <div class="videoTitle">- {{ item.title }}</div>
              <div class="videoTime"></div>
            </li>
          </ul>
          <p v-if="loading" class="center">加载中...</p>
          <p v-if="noMore && videoList.length > 5"  class="center">没有更多了</p>
        </div>
      </div>
    </div>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { VueDebounce } from '../assets/js/util.js'
export default {
  data() {
    return {
      loading: false,
      noMore: false,
      page: 1, // 视频列表分页
      videoList: [], // 视频列表
      details: {}, // 文章/视频详情
      detailsId: '', // 详情id
      plate_id: '', // 板块id
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: '' //url地址
          }
        ],
        poster: '', //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      }
    }
  },

  computed: {
    ...mapState(['mobile']),
    disabled() {
      return this.loading || this.noMore
    }
  },
  mounted() {
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
    }
    // let bottom = document.getElementsByClassName('bottom')[0]
    // bottom.style.display = 'none'
  },
  created() {
    // 视频/文章id
    let id = this.$route.query.detailsId
    this.detailsId = id
    // 板块id
    let plate_id = this.$route.query.plate_id
    this.plate_id = plate_id
    // console.log(id)
    this.getDetails(id, plate_id)
  },
  methods: {
    // 获取视频详情
    async getDetails(detailsId, plate_id) {
      console.log(detailsId)
      let data = {
        plate_id: plate_id,
        type: 2,
        page: this.page,
        limit: 20
      }
      let { data: res } = await this.$http.get(`/view-details/${detailsId}`, {
        params: data
      })
      if (res.state == 200) {
        this.details = res.data
        this.playerOptions.sources[0].src = res.data.shop_file.obs_path
      } else {
        this.$toast(res.message)
        console.log(res.message)
      }
    },
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/zh-CN/home' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    loadMore: VueDebounce('load', 600),
    async load() {
      // 加载视频列表
      this.loading = true
      let data = {
        plate_id: this.plate_id,
        type: 2,
        page: this.page,
        limit: 20
      }
      let {data: res} = await this.$http.get(`/view-details/${this.detailsId}`, {
        params: data
      })
      if (res.state == 200) {
        if (res.data.videoList != false) {
          this.page++
          this.videoList = this.videoList.concat(res.data.videoList)
          this.loading = false
        } else {
          this.loading = false
          this.noMore = true
          return
        }
      } else {
        this.$toast('加载失败~')
        this.loading = false
        this.noMore = true
      }
    },
    changeVideo(url) {
      this.playerOptions.sources[0].src = url
    }
  }
}
</script>

<style lang="less">
.navBar_m {
  display: none;
}
.backTop {
  display: none;
}
#videoid {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
.container_video {
  background: #333333;
  width: 100%;
  margin: 0 auto;
  .list-item {
    cursor: pointer;
    margin-bottom: 20px;
    padding-left: 5px;
  }
  .list-item:hover {
    cursor: pointer;
    background: #4c2f19;
  }
  .video_title {
    width: 100%;
    height: 32px;
    font-family: PingFang-SC-Medium;
    font-size: 18px;
    line-height: 32px;
    color: #ffffff;
    padding-left: 24px;
  }
  .videoWrap {
    width: 100%;
    // height: 812px;
    display: flex;
    justify-content: space-between;
    .left_video {
      width: 83.4%;
    }
    .right_list {
      width: 20%;
      height: 100%;
      padding-left: 14px;
    }
    .right_top {
      width: 100%;
      height: 32px;
      font-family: PingFang-SC-Medium;
      font-size: 18px;
      line-height: 10px;
      color: #ffffff;
    }
  }
  .videoTitle {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: PingFang-SC-Medium;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 32px;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .videoTime {
    width: 100%;
    height: 24px;
    font-family: PingFang-SC-Medium;
    font-size: 12px;
    line-height: 24px;
    color: #999999;
  }
  #mse {
    width: 1606px;
    height: 812px;
  }
  .infinite-list-wrapper {
    width: 100%;
    height: 687px;
    // background: rgba(255, 255, 255, 0.6);
    p {
      color: #fff;
      font-size: 14px;
    }
    .list {
      width: 100%;
      // height: 100%;
    }
  }
}
// body,
// html {
//   background: #333;
// }
@media screen and (max-width: 760px) {
  .container_video {
    height: 100%;
    .list-item {
      cursor: pointer;
      margin-bottom: 0.2rem;
      padding-left: 0.05rem;
    }
    .list-item:hover {
      cursor: pointer;
      background: #4c2f19;
    }
    .video_title {
      width: 100%;
      // height: 0.32rem;
      font-family: PingFang-SC-Medium;
      font-size: 0.28rem;
      line-height: 0.32rem;
      color: #ffffff;
      padding-left: 0.24rem;
      // margin-bottom: 0.18rem;
      padding-top: 0.1rem;
    }
    .videoWrap {
      width: 100%;
      min-height: 8.12rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .left_video {
        width: 100%;
      }
      .right_list {
        width: 100%;
        // height: 100%;
        padding-left: 14px;
      }
      .right_top {
        width: 100%;
        height: 0.32rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.28rem;
        line-height: 10px;
        color: #ffffff;
        margin: 20px 0 10px 0;
      }
    }
    .videoTitle {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family: PingFang-SC-Medium;
      font-size: 0.28rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.32rem;
      letter-spacing: 0px;
      color: #ffffff;
    }
    .videoTime {
      width: 100%;
      height: 0.48rem;
      font-family: PingFang-SC-Medium;
      font-size: 0.24rem;
      line-height: 0.48rem;
      color: #999999;
    }
    .infinite-list-wrapper {
      width: 100%;
      height: 200px;
      // background: rgba(255, 255, 255, 0.6);
      .list {
        width: 100%;
        // height: 50%;
      }
      p {
        color: #fff;
        font-size: 14px;
      }
    }
  }

  // 显示移动端navBar
  .navBar_m {
    display: block;
    width: 100%;
    height: 46px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .leftIcon {
      width: 40px;
      height: 100%;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #999999;
      position: relative;
      z-index: 6;
    }
    .navBarText_m {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      font-family: SourceHanSansSC-Regular;
      font-size: 18px;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  #app {
    padding-top: 48px;
  }
}
.center{
  text-align: center;
}
</style>
